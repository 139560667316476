<template>
  <div>
    <div class="pa-0 ma-0 notification-dialog">
      <div class="preview-notification-wrapper">
        <img
          alt="smart-phone"
          src="@/assets/images/phone-notification-panel.png"
        />
        <div class="main-notification-wrapper">
          <!-- {{ preview_details }} -->
          <!-- <v-row>
            <v-col cols="2">
              <div>asdasdas</div>
            </v-col>
            <v-col cols="10">
              <div>asda</div>
              <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                quas debitis tenetur quam est facilis!
              </div>
            </v-col>
          </v-row> -->

          <div class="d-flex">
            <div class="cp-app-logo-wrapper">
              <img alt="cashpo-app-logo" src="@/assets/images/favicon.png" />
            </div>
            <div class="px-2 preview-wrapper">
              <div
                class="d-flex align-center justify-space-between preview-header"
              >
                <div class="">
                  <span class="font-weight-bold">
                    Cashpo
                  </span>
                  <span class="preview-header-time">
                    7:00 PM
                  </span>
                </div>
                <div>
                  <v-icon color="white" small>mdi-chevron-up</v-icon>
                </div>
              </div>
              <div class="preview-title">
                {{ preview_details.title }}
              </div>
              <div v-if="preview_details.body" class="preview-body">
                {{ preview_details.body }}
              </div>
              <div v-if="preview_details.image" class="preview-image">
                <img alt="banner" :src="preview_details.image" />
                <!-- {{ preview_details.image }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show_dialog: false,
      notification_details: [],
    };
  },
  computed: {
    ...mapGetters({
      preview_details: "notification/getPreviewDetails",
    }),
  },
  methods: {
    async openNotificationPreview() {
      this.show_dialog = true;
    },
  },
};
</script>
<style>
.notification-dialog {
  /* overflow: hidden; */
}
.preview-notification-wrapper {
  /* background: #fff; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-notification-wrapper img {
  width: 80%;
}
.main-notification-wrapper {
  /* overflow-x: hidden; */
  position: absolute;
  border-radius: 10px;
  padding: 10px;
  top: 32%;
  left: 15%;
  width: 70%;
  background: #3f3e3a;
  max-height: 350px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.main-notification-wrapper::-webkit-scrollbar {
  display: none;
}
.cp-app-logo-wrapper {
  display: grid;
  padding: 5px;
  place-items: center;
  border-radius: 50%;
  background: #fff;
  width: 25px;
  height: 25px;
}
.cp-app-logo-wrapper img {
  width: 100%;
}
.preview-wrapper {
  width: 100%;
  color: #fff;
}
.preview-header {
  font-size: 12px;
}
.preview-header-time {
  padding-left: 5px;
  font-size: 10px;
}
.preview-title {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  line-height: 16px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.preview-body {
  padding-top: 7px;
  font-size: 11px;
  /* font-weight: 700; */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  line-height: 16px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.preview-image {
  padding-top: 8px;
}
.preview-image img {
  width: 100%;
  border-radius: 10px;
}
</style>
